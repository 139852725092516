<template>
  <div>
    <div class="shon_banner">
      <div class="container">
        <h1>{{ $t("nil.title") }}</h1>
        <div class="shon_box">
          <div class="nil-icon"></div>
          <p v-html="$t('nil.text')"></p>
        </div>
      </div>
    </div>
    <div class="container">
      <div class="projects">
        <h2 class="section_title">{{ $t("nil.title1") }}</h2>
        <div class="projects__list">
          <div class="projects__item item-2">
            <div class="projects__info">
              <h3>{{ $t("nil.part1") }}</h3>
              <p v-html="$t('nil.text1')"></p>
            </div>
          </div>
          <div class="projects__item item-1">
            <div class="projects__info">
              <h3>{{ $t("nil.part2") }}</h3>
              <p v-html="$t('nil.text2')"></p>
            </div>
          </div>
          <div class="projects__item item-3">
            <div class="projects__info">
              <h3>{{ $t("nil.part3") }}</h3>
              <p v-html="$t('nil.text3')"></p>
            </div>
          </div>
          <div class="projects__item item-4">
            <div class="projects__info">
              <h3>{{ $t("nil.part4") }}</h3>
              <p v-html="$t('nil.text4')"></p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style scoped lang="scss">
.nil-icon {
  width: 117px;
  height: 117px;
  background-image: url("../assets/icons/nil-icon.svg");
  background-repeat: no-repeat;
  background-position: center;
  background-color: #00303a;
  border-radius: 50%;
  left: 50%;
  transform: translateX(-50%);
  top: -50px;
  position: absolute;
}
.shon_box {
  padding: 65px 45px 40px 45px;
  position: relative;
  max-width: 612px;
  background: #00303a;
  margin: 90px auto;
  border-radius: 5px;
  font-size: 20px;
  line-height: 23px;
  &::after {
    content: "";
    width: 20px;
    display: block;
    position: absolute;
    width: 389px;
    height: 43px;
    background: #e66e00;
    border-radius: 2px;
    bottom: -30px;
    left: -50px;
  }
}
.shon_banner {
  color: #ffffff;
  text-align: center;
  padding: 50px 0;
  background-size: cover;
  height: 870px;
  background-image: url("../assets/images/nil-banner.png");
  background-repeat: no-repeat;
  background-position: center;
  h1 {
    font-size: 35px;
    line-height: 41px;
    padding-bottom: 20px;
    border-bottom: 2px solid #ffffff;
  }
}
@media (max-width: 768px) {
  .shon_box {
    font-size: 16px;
    line-height: 23px;
    &::after {
      height: 18px;
      bottom: 0;
    }
  }
  .shon_banner {
    padding: 120px 0 50px 0;
    height: 1200px;
    background-size: auto;
    h1 {
      font-size: 25px;
      padding-bottom: 10px;
    }
  }
  .projects {
    margin: 50px 0;
    &__list {
      display: flex;
      margin-top: 36px;
      align-items: center;
      flex-wrap: wrap;
      gap: 20px;
    }
    &__item {
      max-width: 560px;
      border-radius: 5px;
      background-repeat: no-repeat;
      background-size: cover;
      display: flex;
      padding: 30px;
    }
    &__info {
      color: #ffffff;
      align-self: flex-end;
      h3 {
        font-weight: 500;
        font-size: 30px;
        line-height: 35px;
      }
      p {
        font-size: 18px;
        line-height: 21px;
        font-weight: 300;
        margin-top: 20px;
      }
    }
  }
  .section_title {
    font-size: 18px;
    line-height: 18px;
  }
  .section_title {
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 35px;
    line-height: 41px;
    text-transform: uppercase;
    padding-bottom: 15px;
    border-bottom: 2px solid #e66e00;
    color: #202020;
  }
  .projects__info {
    h3 {
      font-size: 18px;
      line-height: 18px;
    }
    p {
      font-size: 14px;
      line-height: 15px;
      margin-top: 10px;
    }
  }
  .item-1 {
    background-image: url("../assets/images/project1.png");
    height: 280px;
  }
  .item-2 {
    background-image: url("../assets/images/project2.png");
    height: 280px;
  }
  .item-3 {
    background-image: url("../assets/images/project3.png");
    height: 466px;
  }
  .item-4 {
    background-image: url("../assets/images/project4.png");
    height: 466px;
  }
}
</style>
